// src/pages/blog/CommonProblemsPost.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { HiExclamation, HiLightBulb } from 'react-icons/hi';
import CTAButtons from '../../components/shared/CTAButtons';

const COMMON_PROBLEMS = [
  {
    title: "Batería Descargada",
    description: "El problema más frecuente en carretera. Suele ocurrir por luces encendidas, batería antigua o problemas del alternador.",
    symptoms: [
      "El auto no arranca o lo hace con dificultad",
      "Luces débiles o parpadeo del tablero",
      "Ruido de 'click' al girar la llave"
    ],
    prevention: [
      "Revisar la batería cada 6 meses",
      "Apagar todas las luces al estacionar",
      "Cambiar la batería cada 3-4 años"
    ]
  },
  {
    title: "Neumático Pinchado",
    description: "Segunda causa más común de asistencia en ruta. Puede ocurrir por objetos punzantes, presión incorrecta o desgaste.",
    symptoms: [
      "Vibración del volante",
      "Ruido inusual al rodar",
      "El vehículo 'tira' hacia un lado"
    ],
    prevention: [
      "Revisar presión mensualmente",
      "Rotar neumáticos cada 10,000 km",
      "Verificar profundidad del dibujo"
    ]
  },
  {
    title: "Sobrecalentamiento",
    description: "Frecuente en verano o viajes largos. Puede causar daños graves si no se atiende a tiempo.",
    symptoms: [
      "Indicador de temperatura elevada",
      "Vapor saliendo del capó",
      "Olor a refrigerante"
    ],
    prevention: [
      "Revisar nivel de refrigerante",
      "Mantener el radiador limpio",
      "Revisar correa de bomba de agua"
    ]
  },
  {
    title: "Problemas de Combustible",
    description: "Ya sea por quedarse sin combustible o problemas en el sistema de inyección.",
    symptoms: [
      "El motor se apaga repentinamente",
      "Pérdida de potencia",
      "Indicador en reserva"
    ],
    prevention: [
      "Mantener al menos 1/4 de tanque",
      "Usar combustible de calidad",
      "Cambiar filtro según manual"
    ]
  },
  {
    title: "Fallas Eléctricas",
    description: "Pueden afectar desde las luces hasta el sistema de inyección.",
    symptoms: [
      "Luces intermitentes",
      "Fallas en el arranque",
      "Problemas con la radio o tablero"
    ],
    prevention: [
      "Revisar conexiones regularmente",
      "Mantener bornes de batería limpios",
      "Atender ruidos o fallas menores"
    ]
  }
];

export default function CommonProblemsPost() {
  return (
    <>
      <Helmet>
        <title>Los 5 Problemas Más Comunes en Carretera | Grúas Bechan</title>
        <meta 
          name="description" 
          content="Descubre los problemas mecánicos más frecuentes en carretera, cómo identificarlos y prevenirlos. Guía completa de mantenimiento preventivo."
        />
      </Helmet>

      <article className="max-w-4xl mx-auto px-4 py-12">
        <header className="text-center mb-12">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold mb-4"
          >
            Los 5 Problemas Más Comunes en Carretera
          </motion.h1>
          <div className="flex items-center justify-center space-x-4 text-gray-600">
            <span>4 minutos de lectura</span>
            <span>•</span>
            <span>Actualizado: {new Date().toLocaleDateString()}</span>
          </div>
        </header>

        <div className="prose prose-lg max-w-none">
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-8">
            <p className="text-yellow-700">
              <strong>Recomendación:</strong> Guarda nuestro número de emergencia en tu teléfono. 
              La prevención es importante, pero estar preparado para emergencias es fundamental.
            </p>
          </div>

          {COMMON_PROBLEMS.map((problem, index) => (
            <motion.section
              key={problem.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="mb-12"
            >
              <h2 className="flex items-center space-x-2 text-2xl font-bold mb-4">
                <HiExclamation className="text-brand-600 w-6 h-6" />
                <span>{`${index + 1}. ${problem.title}`}</span>
              </h2>

              <p className="mb-4 text-gray-700">{problem.description}</p>

              <div className="grid md:grid-cols-2 gap-6 mt-4">
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="font-semibold mb-3 flex items-center">
                    <HiExclamation className="w-5 h-5 text-red-500 mr-2" />
                    Síntomas
                  </h3>
                  <ul className="space-y-2">
                    {problem.symptoms.map((symptom, idx) => (
                      <li key={idx} className="flex items-start">
                        <span className="text-red-500 mr-2">•</span>
                        {symptom}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="font-semibold mb-3 flex items-center">
                    <HiLightBulb className="w-5 h-5 text-green-500 mr-2" />
                    Prevención
                  </h3>
                  <ul className="space-y-2">
                    {problem.prevention.map((tip, idx) => (
                      <li key={idx} className="flex items-start">
                        <span className="text-green-500 mr-2">•</span>
                        {tip}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </motion.section>
          ))}

          {/* CTA Section */}
          <div className="bg-brand-50 p-8 rounded-lg mt-12">
            <h3 className="text-2xl font-bold mb-4 text-center">
              ¿Necesitas asistencia en ruta?
            </h3>
            <p className="text-center mb-6">
              No importa cuál sea el problema, estamos aquí para ayudarte 24/7.
            </p>
            <div className="flex justify-center">
              <CTAButtons />
            </div>
          </div>
        </div>
      </article>
    </>
  );
}