// src/components/home/HeroSection.jsx
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { HiPhone, HiChat } from 'react-icons/hi';
import { images } from '../../utils/images';
import { PHONE_NUMBER, SOCIAL_MEDIA } from '../../utils/constants';

const SLIDE_DURATION = 5000; // 5 segundos por slide

const HERO_IMAGES = [
  {
    url: images.services.service247,
    title: "Servicio 24/7 en toda la región"
  },
  {
    url: images.services.quilpue,
    title: "Cobertura en Quilpué"
  },
  {
    url: images.services.vina,
    title: "Servicio en Viña del Mar"
  },
  {
    url: images.services.valpo,
    title: "Grúas en Valparaíso"
  }
];

export default function HeroSection() {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Autoplay para el carrusel
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % HERO_IMAGES.length);
    }, SLIDE_DURATION);

    return () => clearInterval(timer);
  }, []);

  return (
    <section className="relative h-[90vh] min-h-[600px] overflow-hidden">
      {/* Carrusel de imágenes */}
      <AnimatePresence initial={false}>
        <motion.div
          key={currentSlide}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          className="absolute inset-0"
        >
          <div
            className="absolute inset-0 bg-cover bg-center bg-no-repeat transition-transform duration-[3000ms] ease-out transform scale-105"
            style={{
              backgroundImage: `url(${HERO_IMAGES[currentSlide].url})`,
            }}
          >
            <div className="absolute inset-0 bg-black/60" />
          </div>
        </motion.div>
      </AnimatePresence>

      {/* Indicadores del carrusel */}
      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {HERO_IMAGES.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={`w-3 h-3 rounded-full transition-all ${
              index === currentSlide
                ? "bg-white w-8"
                : "bg-white/50 hover:bg-white/80"
            }`}
          />
        ))}
      </div>

      {/* Contenido */}
      <div className="relative container h-full flex items-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="max-w-2xl text-white"
        >
          <h1 className="text-5xl md:text-6xl font-bold mb-6">
            Servicio de Grúas 24/7 en Viña del Mar
          </h1>
          <p className="text-xl mb-8">
            Asistencia en ruta inmediata en toda la quinta región.
            Servicio profesional y confiable las 24 horas del día.
          </p>

          {/* Botones de CTA con animaciones y más contexto */}
          <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-4">
            <motion.a
              href={`tel:${PHONE_NUMBER}`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="group flex items-center justify-center w-full sm:w-auto bg-green-500 hover:bg-green-600 text-white px-8 py-4 rounded-full text-lg font-medium shadow-lg hover:shadow-xl transition-all duration-300"
            >
              <HiPhone className="w-6 h-6 mr-2 animate-bounce" />
              <span>Llamar Ahora</span>
              <motion.div
                animate={{
                  x: [0, 5, 0],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
                className="hidden sm:block ml-2 text-sm opacity-80"
              >
                👆 Click para llamar
              </motion.div>
            </motion.a>

            <motion.a
              href={SOCIAL_MEDIA.whatsapp}
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="group flex items-center justify-center w-full sm:w-auto bg-white text-green-600 px-8 py-4 rounded-full text-lg font-medium shadow-lg hover:shadow-xl transition-all duration-300"
            >
              <HiChat className="w-6 h-6 mr-2" />
              <span>WhatsApp</span>
              <motion.div
                animate={{
                  x: [0, 5, 0],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
                className="hidden sm:block ml-2 text-sm opacity-80"
              >
                👆 Click para mensaje
              </motion.div>
            </motion.a>
          </div>

          {/* Número de teléfono explícito */}
          <div className="mt-6 text-center sm:text-left text-white/90">
            <p className="text-lg">
              ¿Prefieres marcar directamente? Llámanos al:
            </p>
            <p className="text-2xl font-bold">
              {PHONE_NUMBER}
            </p>
          </div>
        </motion.div>
      </div>
    </section>
  );
}