export const getPageTitle = (title) => {
    return `${title} | Grúas Bechan`;
  };
  
  export const getPageDescription = (description) => {
    return description || 'Servicio de grúas y asistencia en ruta 24/7 en Viña del Mar y toda la quinta región. Respuesta inmediata y servicio profesional.';
  };
  
  export const DEFAULT_SEO = {
    title: 'Grúas Bechan - Servicio de Grúas 24/7 en Viña del Mar',
    description: 'Servicio de grúas y asistencia en ruta 24/7 en Viña del Mar y toda la quinta región. Respuesta inmediata y servicio profesional.',
    canonical: 'https://gruasbechan.cl',
    openGraph: {
      type: 'website',
      locale: 'es_CL',
      url: 'https://gruasbechan.cl',
      site_name: 'Grúas Bechan',
    },
  };