import React from 'react';
import { motion } from 'framer-motion';
import { HiPhone, HiLocationMarker, HiClock } from 'react-icons/hi';
import CTAButtons from '../shared/CTAButtons';
import { LOCATIONS } from '../../utils/constants';

export default function EmergencySection() {
  return (
    <section className="py-20 bg-brand-600 text-white">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          {/* Left Column - Text */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              ¿Necesitas una grúa con urgencia?
            </h2>
            <p className="text-lg mb-8 text-white/90">
              Estamos disponibles las 24 horas para ayudarte. Servicio rápido y confiable 
              en toda la quinta región.
            </p>
            <CTAButtons />
          </motion.div>

          {/* Right Column - Features */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="space-y-6"
          >
            <div className="flex items-start space-x-4">
              <div className="bg-white/10 p-3 rounded-lg">
                <HiClock className="h-6 w-6" />
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">
                  Servicio 24/7
                </h3>
                <p className="text-white/80">
                  Disponibles a cualquier hora, todos los días del año
                </p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <div className="bg-white/10 p-3 rounded-lg">
                <HiLocationMarker className="h-6 w-6" />
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">
                  Cobertura Regional
                </h3>
                <ul className="text-white/80 space-y-1">
                  {LOCATIONS.map((location, index) => (
                    <li key={index}>
                      {location.city}, {location.region}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <div className="bg-white/10 p-3 rounded-lg">
                <HiPhone className="h-6 w-6" />
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">
                  Respuesta Inmediata
                </h3>
                <p className="text-white/80">
                  Te atenderemos de inmediato y enviaremos ayuda lo antes posible
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}