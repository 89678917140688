// src/components/layout/Navbar.jsx
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HiMenu, HiX, HiPhone } from 'react-icons/hi';
import { NAVIGATION, PHONE_NUMBER } from '../../utils/constants';
import { images } from '../../utils/images';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-20">
          {/* Logo y Nombre */}
          <Link to="/" className="flex items-center space-x-3">
            <img 
              src={images.logo}
              alt="Grúas Bechan" 
              className="h-12 w-auto"
            />
            <div className="hidden md:block">
              <h1 className="text-xl font-bold text-gray-900">Grúas Bechan</h1>
              <p className="text-sm text-gray-600">Servicio 24/7 en toda la V región</p>
            </div>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {NAVIGATION.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={`${
                  location.pathname === item.href
                    ? 'text-brand-600 font-medium'
                    : 'text-gray-700 hover:text-brand-600'
                } transition-colors duration-200`}
              >
                {item.name}
              </Link>
            ))}
            <a
              href={`tel:${PHONE_NUMBER}`}
              className="flex items-center px-4 py-2 bg-brand-600 text-white rounded-full hover:bg-brand-700 transition-colors duration-200"
            >
              <HiPhone className="mr-2 h-5 w-5" />
              <span className="font-medium">{PHONE_NUMBER}</span>
            </a>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
          <a
              href={`tel:${PHONE_NUMBER}`}
              className="mr-4 text-brand-600 font-medium"
            >
              <HiPhone className="h-6 w-6" />
            </a>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-700 hover:text-brand-600"
            >
              {isOpen ? (
                <HiX className="h-6 w-6" />
              ) : (
                <HiMenu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      {isOpen && (
        <div className="md:hidden border-t border-gray-200">
          <div className="px-4 py-3 bg-gray-50">
            <div className="text-base font-medium text-gray-800">Grúas Bechan</div>
            <div className="text-sm text-gray-600">Servicio de Grúas 24/7</div>
          </div>
          <div className="px-2 pt-2 pb-3 space-y-1">
            {NAVIGATION.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                onClick={() => setIsOpen(false)}
                className={`${
                  location.pathname === item.href
                    ? 'bg-brand-50 text-brand-600'
                    : 'text-gray-700 hover:bg-gray-50'
                } block px-3 py-2 rounded-md text-base font-medium`}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
}