// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

// Páginas principales
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import BlogPage from './pages/BlogPage';
import ContactPage from './pages/ContactPage';
import NotFoundPage from './pages/NotFoundPage';

// Posts del blog
import EmergencyGuidePost from './pages/blog/EmergencyGuidePost';
import PreventiveMaintenancePost from './pages/blog/PreventiveMaintenancePost';
import CommonProblemsPost from './pages/blog/CommonProblemsPost';
import TowingServicesGuidePost from './pages/blog/TowingServicesGuidePost';

// Constantes de blog (opcional, puedes usarla para manejar los posts de forma dinámica)
const BLOG_POSTS = [
  {
    id: 1,
    slug: 'que-hacer-pana-carretera',
    component: EmergencyGuidePost
  },
  {
    id: 2,
    slug: 'mantenimiento-preventivo',
    component: PreventiveMaintenancePost
  },
  {
    id: 3,
    slug: 'problemas-comunes-carretera',
    component: CommonProblemsPost
  },
  {
    id: 4,
    slug: 'servicios-grua-guia',
    component: TowingServicesGuidePost
  }
];

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="flex flex-col min-h-screen">
          <Navbar />
          <main className="flex-grow">
            <Routes>
              {/* Rutas principales */}
              <Route path="/" element={<HomePage />} />
              <Route path="/servicios" element={<ServicesPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/contacto" element={<ContactPage />} />
              <Route path="/informacion-contacto" element={<ContactPage />} />
              {/* Rutas de los posts del blog */}
              <Route 
                path="/blog/que-hacer-pana-carretera" 
                element={<EmergencyGuidePost />} 
              />
              <Route 
                path="/blog/mantenimiento-preventivo" 
                element={<PreventiveMaintenancePost />} 
              />
              <Route 
                path="/blog/problemas-comunes-carretera" 
                element={<CommonProblemsPost />} 
              />
              <Route 
                path="/blog/servicios-grua-guia" 
                element={<TowingServicesGuidePost />} 
              />

              {/* Alternativa: Rutas dinámicas usando BLOG_POSTS 
              {BLOG_POSTS.map(post => (
                <Route
                  key={post.id}
                  path={`/blog/${post.slug}`}
                  element={<post.component />}
                />
              ))}
              */}
              {/* Ruta 404 - DEBE IR AL FINAL */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;