// src/components/shared/ServiceCard.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { HiCheck } from 'react-icons/hi';

export default function ServiceCard({ title, description, icon, image, detailedDescription }) {
  return (
    <motion.div
      whileHover={{ y: -5 }}
      className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-all duration-300"
    >
      <div className="relative h-48">
        <img
          src={image}
          alt={title}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
      </div>
      
      <div className="p-6">
        <h3 className="text-xl font-bold mb-3">{title}</h3>
        <p className="text-gray-600 mb-4">{description}</p>
        
        <ul className="space-y-2">
          {detailedDescription.map((detail, index) => (
            <li key={index} className="flex items-center text-gray-700">
              <HiCheck className="text-green-500 mr-2 flex-shrink-0" />
              <span className="text-sm">{detail}</span>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
}