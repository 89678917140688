// src/pages/blog/EmergencyGuidePost.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { HiClock, HiExclamation, HiLightBulb, HiShieldCheck } from 'react-icons/hi';
import CTAButtons from '../../components/shared/CTAButtons';

export default function EmergencyGuidePost() {
  return (
    <>
      <Helmet>
        <title>¿Qué hacer en caso de pana en la carretera? | Grúas Bechan</title>
        <meta 
          name="description" 
          content="Guía completa sobre qué hacer si tu auto se pana en la carretera. Consejos de seguridad y pasos a seguir en caso de emergencia vehicular."
        />
        <meta 
          name="keywords" 
          content="pana carretera, emergencia vehicular, grúa valparaíso, asistencia en ruta, que hacer pana auto"
        />
        {/* Schema.org markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "HowTo",
            "name": "Qué hacer en caso de pana en la carretera",
            "description": "Guía paso a paso sobre qué hacer cuando tu vehículo sufre una pana en la carretera",
            "estimatedCost": {
              "@type": "MonetaryAmount",
              "currency": "CLP",
              "value": "0"
            },
            "step": [
              {
                "@type": "HowToStep",
                "name": "Mantener la calma y buscar zona segura",
                "text": "Enciende las luces de emergencia y busca un lugar seguro para detenerte"
              },
              {
                "@type": "HowToStep",
                "name": "Señalizar",
                "text": "Coloca los triángulos de emergencia a una distancia adecuada"
              },
              {
                "@type": "HowToStep",
                "name": "Solicitar ayuda",
                "text": "Contacta a un servicio de grúas profesional"
              }
            ]
          })}
        </script>
      </Helmet>

      <article className="max-w-4xl mx-auto px-4 py-12">
        {/* Header */}
        <header className="text-center mb-12">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold mb-4"
          >
            ¿Qué hacer en caso de pana en la carretera?
          </motion.h1>
          <div className="flex items-center justify-center space-x-4 text-gray-600">
            <span>5 minutos de lectura</span>
            <span>•</span>
            <span>Actualizado: {new Date().toLocaleDateString()}</span>
          </div>
        </header>

        {/* Contenido Principal */}
        <div className="prose prose-lg max-w-none">
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-8">
            <p className="text-yellow-700">
              <strong>Nota importante:</strong> Este artículo contiene consejos generales de seguridad. 
              En caso de emergencia, siempre prioriza tu seguridad y la de tus acompañantes.
            </p>
          </div>

          <h2 className="flex items-center space-x-2">
            <HiExclamation className="w-6 h-6 text-brand-600" />
            <span>Pasos inmediatos al sufrir una pana</span>
          </h2>

          <ol className="space-y-4">
            <li className="flex items-start space-x-4">
              <span className="font-bold text-brand-600 text-xl">1.</span>
              <div>
                <h3 className="font-semibold">Mantén la calma y busca un lugar seguro</h3>
                <p>
                  Enciende las luces de emergencia inmediatamente y busca un lugar seguro 
                  para detenerte, idealmente la berma o un área amplia fuera de la carretera.
                </p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <span className="font-bold text-brand-600 text-xl">2.</span>
              <div>
                <h3 className="font-semibold">Señaliza adecuadamente</h3>
                <p>
                  Coloca los triángulos de emergencia a una distancia apropiada detrás 
                  de tu vehículo (mínimo 50 metros en carretera).
                </p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <span className="font-bold text-brand-600 text-xl">3.</span>
              <div>
                <h3 className="font-semibold">Evalúa la situación</h3>
                <p>
                  Identifica el problema si es posible, pero no intentes reparaciones 
                  complejas en la carretera.
                </p>
              </div>
            </li>
          </ol>

          <h2 className="flex items-center space-x-2 mt-12">
            <HiShieldCheck className="w-6 h-6 text-brand-600" />
            <span>Medidas de seguridad esenciales</span>
          </h2>

          <ul className="space-y-4">
            <li className="flex items-start space-x-4">
              <HiLightBulb className="w-6 h-6 text-brand-600 flex-shrink-0" />
              <div>
                <h3 className="font-semibold">Mantente visible</h3>
                <p>
                  Usa chaleco reflectante si tienes que salir del vehículo, especialmente 
                  de noche o con poca visibilidad.
                </p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <HiLightBulb className="w-6 h-6 text-brand-600 flex-shrink-0" />
              <div>
                <h3 className="font-semibold">Permanece en un lugar seguro</h3>
                <p>
                  Si es posible, espera la ayuda dentro del vehículo con el cinturón 
                  de seguridad puesto.
                </p>
              </div>
            </li>
          </ul>

          <h2 className="flex items-center space-x-2 mt-12">
            <HiClock className="w-6 h-6 text-brand-600" />
            <span>¿Cuándo llamar a una grúa?</span>
          </h2>

          <p>
            Es recomendable contactar a un servicio de grúa profesional cuando:
          </p>

          <ul className="list-disc pl-6 space-y-2">
            <li>No puedes identificar el problema</li>
            <li>El vehículo no arranca o no se mueve</li>
            <li>Hay ruidos o comportamientos extraños del vehículo</li>
            <li>Te encuentras en una situación peligrosa o zona aislada</li>
            <li>No cuentas con las herramientas necesarias para una reparación básica</li>
          </ul>

          {/* CTA Section */}
          <div className="bg-gray-50 p-8 rounded-lg mt-12">
            <h3 className="text-2xl font-bold mb-4 text-center">
              ¿Necesitas asistencia en ruta ahora?
            </h3>
            <p className="text-center mb-6">
              Estamos disponibles 24/7 para ayudarte. Contacta con nosotros y 
              recibirás ayuda inmediata.
            </p>
            <div className="flex justify-center">
              <CTAButtons />
            </div>
          </div>

          {/* Artículos Relacionados */}
          <div className="mt-12">
            <h3 className="text-2xl font-bold mb-6">Artículos relacionados</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <a href="/blog/mantenimiento-preventivo" className="block">
                <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
                  <h4 className="font-semibold mb-2">
                    Guía de mantenimiento preventivo para tu vehículo
                  </h4>
                  <p className="text-gray-600 text-sm">
                    Aprende a prevenir problemas mecánicos y mantener tu auto en 
                    óptimas condiciones.
                  </p>
                </div>
              </a>
              {/* Más artículos relacionados */}
            </div>
          </div>
        </div>
      </article>
    </>
  );
}