// src/pages/ServicesPage.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { SERVICES, SERVICE_CATEGORIES } from '../utils/constants';
import CTAButtons from '../components/shared/CTAButtons';
import { HiCheck, HiPhone, HiClock, HiLocationMarker } from 'react-icons/hi';

export default function ServicesPage() {
  return (
    <>
      <Helmet>
        <title>Servicios de Grúa y Asistencia en Ruta | Grúas Bechan</title>
        <meta 
          name="description" 
          content="Servicios profesionales de grúa y asistencia en ruta 24/7. Incluye remolque, asistencia mecánica, cambio de neumáticos y más."
        />
      </Helmet>

      {/* Hero Section */}
      <section className="relative bg-gray-900 py-20 px-4 sm:px-6 lg:px-8">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-brand-600/90 to-brand-900/90" />
        </div>
        <div className="relative max-w-7xl mx-auto text-center">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl"
          >
            Nuestros Servicios
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="mt-6 text-xl text-white/90 max-w-3xl mx-auto"
          >
            Ofrecemos una amplia gama de servicios de asistencia en ruta disponibles 24/7.
            Nuestro equipo profesional está listo para ayudarte en cualquier emergencia.
          </motion.p>
        </div>
      </section>

      {/* Características Principales */}
      <section className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="flex items-center space-x-4 p-6 bg-gray-50 rounded-lg"
            >
              <HiClock className="w-8 h-8 text-brand-600" />
              <div>
                <h3 className="font-semibold">Servicio 24/7</h3>
                <p className="text-sm text-gray-600">Disponibles todo el día</p>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="flex items-center space-x-4 p-6 bg-gray-50 rounded-lg"
            >
              <HiLocationMarker className="w-8 h-8 text-brand-600" />
              <div>
                <h3 className="font-semibold">Cobertura Regional</h3>
                <p className="text-sm text-gray-600">Toda la quinta región</p>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="flex items-center space-x-4 p-6 bg-gray-50 rounded-lg"
            >
              <HiPhone className="w-8 h-8 text-brand-600" />
              <div>
                <h3 className="font-semibold">Respuesta Rápida</h3>
                <p className="text-sm text-gray-600">Atención inmediata</p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Listado de Servicios por Categoría */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {SERVICE_CATEGORIES.map((category, categoryIndex) => (
            <div key={category.id} className="mb-16 last:mb-0">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className="border-l-4 border-brand-600 pl-4 mb-8"
              >
                <h2 className="text-3xl font-bold text-gray-900">
                  {category.title}
                </h2>
              </motion.div>

              <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                {category.services.map((serviceId, serviceIndex) => {
                  const service = SERVICES.find(s => s.id === serviceId);
                  return service ? (
                    <motion.div
                      key={service.id}
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: serviceIndex * 0.1 }}
                      className="bg-white rounded-lg shadow-lg overflow-hidden"
                    >
                      <div className="relative h-48">
                        <img
                          src={service.image}
                          alt={service.title}
                          className="w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                        <div className="absolute bottom-4 left-4 right-4">
                          <h3 className="text-xl font-bold text-white">
                            {service.title}
                          </h3>
                        </div>
                      </div>
                      <div className="p-6">
                        <p className="text-gray-600 mb-4">
                          {service.description}
                        </p>
                        <ul className="space-y-2">
                          {service.detailedDescription.map((detail, index) => (
                            <li 
                              key={index}
                              className="flex items-start space-x-2 text-sm"
                            >
                              <HiCheck className="w-5 h-5 text-brand-600 flex-shrink-0 mt-0.5" />
                              <span className="text-gray-600">{detail}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </motion.div>
                  ) : null;
                })}
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-brand-600 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-3xl font-bold text-white mb-4"
            >
              ¿Necesitas asistencia inmediata?
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="text-white/90 text-lg mb-8 max-w-2xl mx-auto"
            >
              Nuestro equipo está listo para ayudarte. Contacta con nosotros ahora 
              y recibirás atención inmediata.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="flex justify-center"
            >
              <CTAButtons />
            </motion.div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">
              Preguntas Frecuentes sobre Servicios
            </h2>
            <p className="text-gray-600">
              Respuestas a las dudas más comunes sobre nuestros servicios
            </p>
          </div>

          <div className="grid gap-8 md:grid-cols-2 max-w-4xl mx-auto">
            {[
              {
                q: "¿Cuál es el tiempo promedio de llegada?",
                a: "Nuestro tiempo promedio de llegada es de 15-30 minutos dentro del área metropolitana, dependiendo de la ubicación y el tráfico."
              },
              {
                q: "¿Qué métodos de pago aceptan?",
                a: "Aceptamos efectivo, transferencias bancarias y tarjetas de débito/crédito para tu comodidad."
              },
              {
                q: "¿Trabajan en días festivos?",
                a: "Sí, nuestro servicio está disponible 24/7, incluyendo días festivos y fines de semana."
              },
              {
                q: "¿Qué área geográfica cubren?",
                a: "Cubrimos toda la quinta región, incluyendo Viña del Mar, Valparaíso, Quilpué y áreas circundantes."
              }
            ].map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-50 p-6 rounded-lg"
              >
                <h3 className="font-semibold text-lg mb-2">{faq.q}</h3>
                <p className="text-gray-600">{faq.a}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}