// src/pages/blog/PreventiveMaintenancePost.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { 
 HiCog, 
 HiCheckCircle, 
 HiExclamation, 
 HiCalendar,
 HiClock
} from 'react-icons/hi';
import CTAButtons from '../../components/shared/CTAButtons';

const MAINTENANCE_CHECKLIST = [
 {
   title: "Revisiones Mensuales",
   items: [
     "Nivel de aceite del motor",
     "Presión de los neumáticos",
     "Nivel de líquido refrigerante",
     "Funcionamiento de luces",
     "Nivel de líquido limpiaparabrisas"
   ]
 },
 {
   title: "Revisiones Trimestrales",
   items: [
     "Estado de la batería",
     "Desgaste de los frenos",
     "Alineación y balanceo",
     "Filtro de aire",
     "Estado de las correas"
   ]
 },
 {
   title: "Revisiones Anuales",
   items: [
     "Cambio de aceite y filtros",
     "Revisión del sistema de suspensión",
     "Diagnóstico computarizado",
     "Estado general de la transmisión",
     "Revisión del sistema de escape"
   ]
 }
];

export default function PreventiveMaintenancePost() {
 return (
   <>
     <Helmet>
       <title>Guía Completa: Mantenimiento Preventivo de tu Vehículo | Grúas Bechan</title>
       <meta 
         name="description" 
         content="Aprende todo sobre el mantenimiento preventivo de tu auto. Guía completa con consejos prácticos para evitar panas y problemas mecánicos."
       />
       <meta 
         name="keywords" 
         content="mantenimiento auto, prevención panas, mecánica básica, cuidado vehicular, revisión auto"
       />
       {/* Schema.org markup */}
       <script type="application/ld+json">
         {JSON.stringify({
           "@context": "https://schema.org",
           "@type": "Article",
           "mainEntityOfPage": {
             "@type": "WebPage",
             "@id": "https://gruasbechan.cl/blog/mantenimiento-preventivo"
           },
           "headline": "Guía Completa: Mantenimiento Preventivo de tu Vehículo",
           "description": "Aprende todo sobre el mantenimiento preventivo de tu auto para evitar problemas mecánicos.",
           "image": "URL_DE_LA_IMAGEN",
           "author": {
             "@type": "Organization",
             "name": "Grúas Bechan"
           },
           "publisher": {
             "@type": "Organization",
             "name": "Grúas Bechan",
             "logo": {
               "@type": "ImageObject",
               "url": "URL_DEL_LOGO"
             }
           },
           "datePublished": new Date().toISOString(),
           "dateModified": new Date().toISOString()
         })}
       </script>
     </Helmet>

     <article className="max-w-4xl mx-auto px-4 py-12">
       {/* Header */}
       <header className="text-center mb-12">
         <motion.h1
           initial={{ opacity: 0, y: 20 }}
           animate={{ opacity: 1, y: 0 }}
           className="text-4xl font-bold mb-4"
         >
           Guía Completa: Cómo Prevenir Problemas Mecánicos en tu Auto
         </motion.h1>
         <div className="flex items-center justify-center space-x-4 text-gray-600">
           <span>8 minutos de lectura</span>
           <span>•</span>
           <span>Actualizado: {new Date().toLocaleDateString()}</span>
         </div>
       </header>

       {/* Introducción */}
       <div className="prose prose-lg max-w-none">
         <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mb-8">
           <p className="text-blue-700">
             <strong>Consejo profesional:</strong> El mantenimiento preventivo 
             regular puede ahorrarte hasta un 30% en reparaciones mayores y 
             extender la vida útil de tu vehículo.
           </p>
         </div>

         <h2 className="flex items-center space-x-2">
           <HiCog className="w-6 h-6 text-brand-600" />
           <span>Checklist de Mantenimiento Preventivo</span>
         </h2>

         {MAINTENANCE_CHECKLIST.map((section, index) => (
           <motion.div
             key={section.title}
             initial={{ opacity: 0, x: -20 }}
             animate={{ opacity: 1, x: 0 }}
             transition={{ delay: index * 0.1 }}
             className="mb-8"
           >
             <h3 className="flex items-center space-x-2 text-xl font-semibold">
               <HiCalendar className="w-5 h-5 text-brand-600" />
               <span>{section.title}</span>
             </h3>
             <ul className="mt-4 space-y-2">
               {section.items.map((item, itemIndex) => (
                 <li key={itemIndex} className="flex items-start space-x-3">
                   <HiCheckCircle className="w-5 h-5 text-green-500 flex-shrink-0 mt-1" />
                   <span>{item}</span>
                 </li>
               ))}
             </ul>
           </motion.div>
         ))}

         <h2 className="flex items-center space-x-2 mt-12">
           <HiExclamation className="w-6 h-6 text-brand-600" />
           <span>Señales de Alerta: ¿Cuándo Preocuparse?</span>
         </h2>

         <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
           <div className="bg-white p-6 rounded-lg shadow-md">
             <h3 className="font-semibold mb-3">Sonidos Inusuales</h3>
             <ul className="space-y-2 text-gray-700">
               <li>• Chirridos al frenar</li>
               <li>• Golpeteos en el motor</li>
               <li>• Ruidos en la suspensión</li>
               <li>• Silbidos al acelerar</li>
             </ul>
           </div>
           <div className="bg-white p-6 rounded-lg shadow-md">
             <h3 className="font-semibold mb-3">Problemas de Rendimiento</h3>
             <ul className="space-y-2 text-gray-700">
               <li>• Consumo excesivo de combustible</li>
               <li>• Pérdida de potencia</li>
               <li>• Vibraciones inusuales</li>
               <li>• Dificultad para arrancar</li>
             </ul>
           </div>
         </div>

         <h2 className="flex items-center space-x-2 mt-12">
           <HiClock className="w-6 h-6 text-brand-600" />
           <span>Intervalos de Mantenimiento Recomendados</span>
         </h2>

         <div className="overflow-x-auto">
           <table className="min-w-full mt-4">
             <thead>
               <tr className="bg-gray-50">
                 <th className="px-6 py-3 text-left">Componente</th>
                 <th className="px-6 py-3 text-left">Frecuencia</th>
                 <th className="px-6 py-3 text-left">Importancia</th>
               </tr>
             </thead>
             <tbody className="divide-y divide-gray-200">
               <tr>
                 <td className="px-6 py-4">Aceite y filtro</td>
                 <td className="px-6 py-4">Cada 10,000 km</td>
                 <td className="px-6 py-4">Alta</td>
               </tr>
               <tr>
                 <td className="px-6 py-4">Frenos</td>
                 <td className="px-6 py-4">Cada 20,000 km</td>
                 <td className="px-6 py-4">Alta</td>
               </tr>
               <tr>
                 <td className="px-6 py-4">Neumáticos</td>
                 <td className="px-6 py-4">Cada 40,000 km</td>
                 <td className="px-6 py-4">Alta</td>
               </tr>
             </tbody>
           </table>
         </div>

         {/* Tips Prácticos */}
         <div className="bg-gray-50 p-6 rounded-lg mt-12">
           <h3 className="text-xl font-bold mb-4">Tips Prácticos para el Día a Día</h3>
           <ul className="space-y-3">
             <li className="flex items-start space-x-3">
               <HiCheckCircle className="w-5 h-5 text-green-500 flex-shrink-0 mt-1" />
               <span>Revisa los niveles de fluidos antes de viajes largos</span>
             </li>
             <li className="flex items-start space-x-3">
               <HiCheckCircle className="w-5 h-5 text-green-500 flex-shrink-0 mt-1" />
               <span>Mantén un registro de mantenimiento detallado</span>
             </li>
             <li className="flex items-start space-x-3">
               <HiCheckCircle className="w-5 h-5 text-green-500 flex-shrink-0 mt-1" />
               <span>Atiende inmediatamente cualquier luz de advertencia</span>
             </li>
           </ul>
         </div>

         {/* CTA Section */}
         <div className="bg-brand-50 p-8 rounded-lg mt-12">
           <h3 className="text-2xl font-bold mb-4 text-center">
             ¿Necesitas asistencia profesional?
           </h3>
           <p className="text-center mb-6">
             No esperes a tener una emergencia. Contacta con nosotros para una 
             evaluación preventiva de tu vehículo.
           </p>
           <div className="flex justify-center">
             <CTAButtons />
           </div>
         </div>

         {/* Artículos Relacionados */}
         <div className="mt-12">
           <h3 className="text-2xl font-bold mb-6">Lecturas Recomendadas</h3>
           <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
             <a href="/blog/que-hacer-pana-carretera" className="block">
               <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
                 <h4 className="font-semibold mb-2">
                   Qué hacer en caso de pana en la carretera
                 </h4>
                 <p className="text-gray-600 text-sm">
                   Guía completa de pasos a seguir cuando tu vehículo sufre una avería.
                 </p>
               </div>
             </a>
             {/* Espacio para más artículos relacionados */}
           </div>
         </div>
       </div>
     </article>
   </>
 );
}