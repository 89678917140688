import React from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { 
  HiPhone, 
  HiMail, 
  HiLocationMarker, 
  HiClock,
  HiChatAlt2
} from 'react-icons/hi';
import { FaWhatsapp, FaFacebookMessenger, FaInstagram } from 'react-icons/fa';
import { PHONE_NUMBER, EMAIL, LOCATIONS, SOCIAL_MEDIA } from '../utils/constants';

export default function ContactPage() {
  return (
    <>
      <Helmet>
        <title>Contacto | Grúas Bechan</title>
        <meta 
          name="description" 
          content="Contacta con Grúas Bechan para asistencia en ruta 24/7. Servicio inmediato en toda la quinta región."
        />
      </Helmet>

      <div className="bg-gray-50 py-12">
        <div className="container">
          {/* Header */}
          <div className="text-center mb-16">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Contacto
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Estamos disponibles 24/7 para ayudarte. Elige el método de contacto 
              que prefieras.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-12">
            {/* Contact Information */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="space-y-8"
            >
              <h2 className="text-2xl font-bold mb-6">
                Información de Contacto
              </h2>

              {/* Contact Cards */}
              <div className="grid gap-6">
                {/* Phone */}
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex items-center mb-4">
                    <HiPhone className="h-6 w-6 text-brand-600 mr-3" />
                    <h3 className="text-xl font-semibold">Teléfono</h3>
                  </div>
                  <a 
                    href={`tel:${PHONE_NUMBER}`}
                    className="text-lg text-gray-700 hover:text-brand-600"
                  >
                    {PHONE_NUMBER}
                  </a>
                  <p className="text-sm text-gray-500 mt-2">
                    Disponible 24/7 para emergencias
                  </p>
                </div>

                {/* WhatsApp */}
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex items-center mb-4">
                    <FaWhatsapp className="h-6 w-6 text-green-500 mr-3" />
                    <h3 className="text-xl font-semibold">WhatsApp</h3>
                  </div>
                  <a 
                    href={SOCIAL_MEDIA.whatsapp}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-lg text-gray-700 hover:text-green-500"
                  >
                    {PHONE_NUMBER}
                  </a>
                  <p className="text-sm text-gray-500 mt-2">
                    Chat directo para consultas
                  </p>
                </div>

                {/* Email */}
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex items-center mb-4">
                    <HiMail className="h-6 w-6 text-brand-600 mr-3" />
                    <h3 className="text-xl font-semibold">Email</h3>
                  </div>
                  <a 
                    href={`mailto:${EMAIL}`}
                    className="text-lg text-gray-700 hover:text-brand-600"
                  >
                    {EMAIL}
                  </a>
                  <p className="text-sm text-gray-500 mt-2">
                    Para consultas generales y cotizaciones
                  </p>
                </div>

                {/* Social Media */}
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex items-center mb-4">
                    <HiChatAlt2 className="h-6 w-6 text-brand-600 mr-3" />
                    <h3 className="text-xl font-semibold">Redes Sociales</h3>
                  </div>
                  <div className="flex space-x-4">
                    <a 
                      href={SOCIAL_MEDIA.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-700"
                    >
                      <FaFacebookMessenger className="h-8 w-8" />
                    </a>
                    <a 
                      href={SOCIAL_MEDIA.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-pink-600 hover:text-pink-700"
                    >
                      <FaInstagram className="h-8 w-8" />
                    </a>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Locations */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              className="space-y-8"
            >
              <h2 className="text-2xl font-bold mb-6">
                Nuestras Ubicaciones
              </h2>

              <div className="grid gap-6">
                {LOCATIONS.map((location, index) => (
                  <div 
                    key={index}
                    className="bg-white p-6 rounded-lg shadow-md"
                  >
                    <div className="flex items-center mb-4">
                      <HiLocationMarker className="h-6 w-6 text-brand-600 mr-3" />
                      <h3 className="text-xl font-semibold">{location.name}</h3>
                    </div>
                    <p className="text-gray-700">
                      {location.address}
                    </p>
                    <p className="text-gray-600">
                      {location.city}, {location.region}
                    </p>
                  </div>
                ))}

                <div className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex items-center mb-4">
                    <HiClock className="h-6 w-6 text-brand-600 mr-3" />
                    <h3 className="text-xl font-semibold">Horario de Atención</h3>
                  </div>
                  <p className="text-gray-700">
                    24 horas al día
                  </p>
                  <p className="text-gray-700">
                    7 días a la semana
                  </p>
                  <p className="text-sm text-gray-500 mt-2">
                    Incluye días festivos y feriados
                  </p>
                </div>
              </div>

              {/* Map (opcional) */}
              <div className="bg-white p-6 rounded-lg shadow-md">
                <iframe
                  title="Ubicación Grúas Bechan"
                  src="https://www.google.com/maps/embed?pb=YOUR_MAPS_EMBED_URL"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  className="rounded-lg"
                ></iframe>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}