// src/utils/images.js
import logo from '../assets/images/logo-n.png';
import logoWhite from '../assets/images/logo-n.png';



//Carretera

import CarreteraImg from '../assets/images/carretera.jpg';
// Servicios
import serviceHero from '../assets/images/services/gruas_bechan_gruas_24_horas.jpg';
import serviceQuilpue from '../assets/images/services/gruas_bechan_quilpue.jpg';
import serviceBateria from '../assets/images/services/gruas_bechan_asistencia en ruta pana poir batería rescatado.jpg';
import serviceRescate from '../assets/images/services/gruas_bechan_rescates_a_bajo_precio_barato.jpg';
import serviceVina from '../assets/images/services/gruas_bechan_rescate_viña_del_mar.jpg';
import serviceValpo from '../assets/images/services/gruas_bechan_valparaiso.jpg';
import service247 from '../assets/images/services/gruas_bechan_viña_del_mar_24_7.jpg';

// Reviews
import reviewImage from '../assets/images/reviews/excelente-servicio-barato-y-rapido.jpg';

// About
import aboutImage from '../assets/images/about/quienes-somos-gruas-bechan.jpg';

export const images = {
  logo,
  logoWhite,
  CarreteraImg,
  hero: serviceHero,
  services: {
    quilpue: serviceQuilpue,
    bateria: serviceBateria,
    rescate: serviceRescate,
    vina: serviceVina,
    valpo: serviceValpo,
    service247: service247
  },
  reviews: reviewImage,
  about: aboutImage
};

export default images;