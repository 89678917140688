// src/components/layout/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { HiPhone, HiMail, HiLocationMarker, HiClock } from 'react-icons/hi';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { images } from '../../utils/images';
import { COMPANY_NAME, PHONE_NUMBER, EMAIL, SOCIAL_MEDIA, LOCATIONS } from '../../utils/constants';

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="container mx-auto py-16 px-4">
        {/* Sección Principal */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Columna 1 - Información de la empresa */}
          <div className="space-y-6">
            <div className="flex items-center space-x-4">
              <img 
                src={images.logoWhite}
                alt={COMPANY_NAME} 
                className="h-12 w-auto"
              />
              <div>
                <h2 className="text-xl font-bold text-white">Grúas Bechan</h2>
                <p className="text-sm text-gray-400">Desde 2019 a tu servicio</p>
              </div>
            </div>
            <p className="text-gray-400">
              Servicio profesional de grúas y asistencia en ruta disponible 24/7 
              en toda la quinta región.
            </p>
            <div className="flex space-x-4">
              <a 
                href={SOCIAL_MEDIA.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <FaFacebook className="h-6 w-6" />
              </a>
              <a 
                href={SOCIAL_MEDIA.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <FaInstagram className="h-6 w-6" />
              </a>
              <a 
                href={SOCIAL_MEDIA.whatsapp}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <FaWhatsapp className="h-6 w-6" />
              </a>
            </div>
          </div>

          {/* Columna 2 - Links rápidos */}
          <div>
            <h3 className="text-lg font-bold text-white mb-6">Enlaces Rápidos</h3>
            <ul className="space-y-4">
              <li>
                <Link to="/servicios" className="text-gray-400 hover:text-white transition-colors">
                  Nuestros Servicios
                </Link>
              </li>
              <li>
                <Link to="/contacto" className="text-gray-400 hover:text-white transition-colors">
                  Contacto
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-gray-400 hover:text-white transition-colors">
                  Blog y Noticias
                </Link>
              </li>
            </ul>
          </div>

          {/* Columna 3 - Contacto */}
          <div>
            <h3 className="text-lg font-bold text-white mb-6">Contacto</h3>
            <ul className="space-y-4">
              <li className="flex items-center space-x-3">
                <HiPhone className="h-5 w-5 text-brand-500" />
                <a href={`tel:${PHONE_NUMBER}`} className="text-gray-400 hover:text-white transition-colors">
                  {PHONE_NUMBER}
                </a>
              </li>
              <li className="flex items-center space-x-3">
                <HiMail className="h-5 w-5 text-brand-500" />
                <a href={`mailto:${EMAIL}`} className="text-gray-400 hover:text-white transition-colors">
                  {EMAIL}
                </a>
              </li>
              <li className="flex items-center space-x-3">
                <HiClock className="h-5 w-5 text-brand-500" />
                <span className="text-gray-400">24/7 - 365 días</span>
              </li>
            </ul>
          </div>

          {/* Columna 4 - Ubicaciones */}
          <div>
            <h3 className="text-lg font-bold text-white mb-6">Ubicaciones</h3>
            {LOCATIONS.map((location, index) => (
              <div key={index} className="mb-4 last:mb-0">
                <div className="flex items-start space-x-3">
                  <HiLocationMarker className="h-5 w-5 text-brand-500 mt-1" />
                  <div className="text-gray-400">
                    <p className="font-medium text-white">{location.name}</p>
                    <p>{location.address}</p>
                    <p>{location.city}, {location.region}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Pie del Footer */}
        <div className="border-t border-gray-800 mt-12 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-400 text-sm">
              © {new Date().getFullYear()} Grúas Bechan. Todos los derechos reservados.
            </p>
            <div className="mt-4 md:mt-0">
              <p className="text-gray-400 text-sm">
                Grúas Bechan - Tu aliado en la ruta 24/7
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}