import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSection from '../components/home/HeroSection';
import ServiceHighlights from '../components/home/ServiceHighlights';
import ReviewsSection from '../components/home/ReviewsSection';
import EmergencySection from '../components/home/EmergencySection';
import { DEFAULT_SEO } from '../utils/seo';

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>{DEFAULT_SEO.title}</title>
        <meta name="description" content={DEFAULT_SEO.description} />
        <link rel="canonical" href={DEFAULT_SEO.canonical} />
        {/* Schema.org markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Grúas Bechan",
            "image": "https://gruasbechan.cl/logo.svg",
            "description": DEFAULT_SEO.description,
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Etchevers Alto",
              "addressLocality": "Viña del Mar",
              "addressRegion": "Valparaíso",
              "addressCountry": "CL"
            },
            "priceRange": "$$",
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday", "Tuesday", "Wednesday", "Thursday",
                "Friday", "Saturday", "Sunday"
              ],
              "opens": "00:00",
              "closes": "23:59"
            }
          })}
        </script>
      </Helmet>

      <div className="flex flex-col min-h-screen">
        <HeroSection />
        <ServiceHighlights />
        <ReviewsSection />
        <EmergencySection />
      </div>
    </>
  );
}