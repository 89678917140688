// src/pages/BlogPage.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { HiClock, HiChevronRight, HiSearch } from 'react-icons/hi';
import { images } from '../utils/images';

const BLOG_POSTS = [
  {
    id: 1,
    title: "Qué hacer en caso de pana en la carretera",
    slug: "que-hacer-pana-carretera",
    excerpt: "Guía completa con consejos de seguridad y pasos a seguir cuando tu vehículo presenta problemas en la carretera.",
    date: "2024-03-15",
    image: images.CarreteraImg,
    category: "Guías",
    readTime: "5 min",
    featured: true
  },
  {
    id: 2,
    title: "Guía Completa: Cómo Prevenir Problemas Mecánicos",
    slug: "mantenimiento-preventivo",
    excerpt: "Aprende todo sobre el mantenimiento preventivo de tu auto para evitar problemas y ahorrar en reparaciones.",
    date: "2024-03-20",
    image: images.services.bateria,
    category: "Mantenimiento",
    readTime: "8 min",
    featured: true
  },
  // Más posts de ejemplo para contenido
  {
    id: 3,
    title: "Los 5 problemas más comunes en carretera",
    slug: "problemas-comunes-carretera",
    excerpt: "Descubre cuáles son las averías más frecuentes y cómo prevenirlas antes de salir a la ruta.",
    date: "2024-03-10",
    image: images.services.rescate,
    category: "Tips",
    readTime: "4 min"
  },
  {
    id: 4,
    title: "Servicios de grúa: Todo lo que debes saber",
    slug: "servicios-grua-guia",
    excerpt: "Conoce los diferentes tipos de servicios de grúa y cuándo necesitas cada uno.",
    date: "2024-03-05",
    image: images.services.valpo,
    category: "Servicios",
    readTime: "6 min"
  }
];

const CATEGORIES = [
  { name: "Todos", slug: "todos" },
  { name: "Guías", slug: "guias" },
  { name: "Mantenimiento", slug: "mantenimiento" },
  { name: "Tips", slug: "tips" },
  { name: "Servicios", slug: "servicios" }
];

export default function BlogPage() {
  const [selectedCategory, setSelectedCategory] = React.useState("todos");
  const [searchQuery, setSearchQuery] = React.useState("");

  const filteredPosts = BLOG_POSTS.filter(post => {
    const matchesCategory = selectedCategory === "todos" || post.category.toLowerCase() === selectedCategory;
    const matchesSearch = post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         post.excerpt.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  return (
    <>
      <Helmet>
        <title>Blog de Consejos y Guías | Grúas Bechan</title>
        <meta 
          name="description" 
          content="Consejos útiles sobre mantenimiento vehicular, seguridad en carretera y guías prácticas para conductores. Blog especializado en asistencia en ruta."
        />
        <meta 
          name="keywords" 
          content="blog grúas, consejos mecánicos, asistencia ruta, mantenimiento auto, seguridad vial"
        />
      </Helmet>

      <div className="bg-gray-50 min-h-screen">
        {/* Hero Section */}
        <section className="relative bg-brand-600 py-20">
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-r from-brand-600/90 to-brand-900/90" />
          </div>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-4xl font-extrabold text-white sm:text-5xl lg:text-6xl"
            >
              Blog y Recursos
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="mt-6 text-xl text-white/90 max-w-3xl mx-auto"
            >
              Guías prácticas, consejos útiles y todo lo que necesitas saber 
              sobre mantenimiento vehicular y seguridad en carretera.
            </motion.p>
          </div>
        </section>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Filtros y Búsqueda */}
          <div className="flex flex-col md:flex-row justify-between items-center mb-12 space-y-4 md:space-y-0">
            <div className="flex flex-wrap gap-2">
              {CATEGORIES.map(category => (
                <button
                  key={category.slug}
                  onClick={() => setSelectedCategory(category.slug)}
                  className={`px-4 py-2 rounded-full transition-colors ${
                    selectedCategory === category.slug
                      ? "bg-brand-600 text-white"
                      : "bg-white text-gray-600 hover:bg-gray-100"
                  }`}
                >
                  {category.name}
                </button>
              ))}
            </div>
            <div className="relative w-full md:w-auto">
              <HiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Buscar artículos..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full md:w-64 pl-10 pr-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-brand-600 focus:border-transparent"
              />
            </div>
          </div>

          {/* Posts Destacados */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            {BLOG_POSTS.filter(post => post.featured).map(post => (
              <motion.article
                key={post.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="bg-white rounded-xl shadow-lg overflow-hidden"
              >
                <Link to={`/blog/${post.slug}`}>
                  <div className="relative h-64">
                    <img
                      src={post.image}
                      alt={post.title}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                    <div className="absolute bottom-4 left-4 right-4">
                      <span className="inline-block px-3 py-1 bg-brand-600 text-white text-sm rounded-full mb-2">
                        {post.category}
                      </span>
                      <h2 className="text-2xl font-bold text-white">{post.title}</h2>
                    </div>
                  </div>
                  <div className="p-6">
                    <p className="text-gray-600 mb-4">{post.excerpt}</p>
                    <div className="flex items-center justify-between text-sm text-gray-500">
                      <div className="flex items-center space-x-2">
                        <HiClock className="w-4 h-4" />
                        <span>{post.readTime}</span>
                      </div>
                      <span>{new Date(post.date).toLocaleDateString()}</span>
                    </div>
                  </div>
                </Link>
              </motion.article>
            ))}
          </div>

          {/* Lista de Posts */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {filteredPosts.filter(post => !post.featured).map((post, index) => (
              <motion.article
                key={post.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
              >
                <Link to={`/blog/${post.slug}`}>
                  <div className="relative h-48">
                    <img
                      src={post.image}
                      alt={post.title}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute top-4 left-4">
                      <span className="inline-block px-3 py-1 bg-brand-600 text-white text-sm rounded-full">
                        {post.category}
                      </span>
                    </div>
                  </div>
                  <div className="p-6">
                    <h3 className="font-bold text-xl mb-2">{post.title}</h3>
                    <p className="text-gray-600 text-sm mb-4">{post.excerpt}</p>
                    <div className="flex items-center justify-between text-sm text-gray-500">
                      <div className="flex items-center space-x-2">
                        <HiClock className="w-4 h-4" />
                        <span>{post.readTime}</span>
                      </div>
                      <span>{new Date(post.date).toLocaleDateString()}</span>
                    </div>
                  </div>
                </Link>
              </motion.article>
            ))}
          </div>

          {/* No Results Message */}
          {filteredPosts.length === 0 && (
            <div className="text-center py-12">
              <p className="text-gray-600 text-lg">
                No se encontraron artículos que coincidan con tu búsqueda.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}