// src/pages/NotFoundPage.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { HiHome, HiPhone } from 'react-icons/hi';
import CTAButtons from '../components/shared/CTAButtons';

export default function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>Página no encontrada | Grúas Bechan</title>
        <meta 
          name="description" 
          content="La página que buscas no existe. Vuelve al inicio o contáctanos para asistencia inmediata."
        />
      </Helmet>

      <div className="min-h-[70vh] flex items-center justify-center px-4 py-12">
        <div className="max-w-xl text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {/* Número 404 grande */}
            <h1 className="text-9xl font-bold text-brand-600 mb-8">404</h1>

            {/* Mensaje principal */}
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              ¡Ups! Página no encontrada
            </h2>

            {/* Mensaje secundario */}
            <p className="text-gray-600 mb-8">
              Parece que te has quedado en pana en una ruta que no existe. 
              ¡Pero no te preocupes! Estamos aquí para ayudarte.
            </p>

            {/* Opciones de navegación */}
            <div className="space-y-4">
              {/* Botones principales */}
              <div className="flex flex-col sm:flex-row justify-center gap-4 mb-6">
                <Link 
                  to="/"
                  className="inline-flex items-center justify-center px-6 py-3 bg-brand-600 text-white rounded-full hover:bg-brand-700 transition-colors"
                >
                  <HiHome className="w-5 h-5 mr-2" />
                  Volver al inicio
                </Link>
                <a 
                  href="tel:+56940485185"
                  className="inline-flex items-center justify-center px-6 py-3 bg-green-600 text-white rounded-full hover:bg-green-700 transition-colors"
                >
                  <HiPhone className="w-5 h-5 mr-2" />
                  Llamar ahora
                </a>
              </div>

              {/* Mensaje de asistencia */}
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-2">
                  ¿Necesitas asistencia inmediata?
                </h3>
                <p className="text-gray-600 mb-4">
                  Estamos disponibles 24/7 para ayudarte, no importa dónde te encuentres.
                </p>
                <CTAButtons />
              </div>

              {/* Links rápidos */}
              <div className="mt-8">
                <p className="text-sm text-gray-500 mb-2">Enlaces útiles:</p>
                <div className="flex flex-wrap justify-center gap-4">
                  <Link 
                    to="/servicios"
                    className="text-brand-600 hover:text-brand-700"
                  >
                    Servicios
                  </Link>
                  <Link 
                    to="/blog"
                    className="text-brand-600 hover:text-brand-700"
                  >
                    Blog
                  </Link>
                  <Link 
                    to="/contacto"
                    className="text-brand-600 hover:text-brand-700"
                  >
                    Contacto
                  </Link>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}