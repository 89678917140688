import React from 'react';
import { motion } from 'framer-motion';
import { HiStar } from 'react-icons/hi';

const REVIEWS = [
  {
    id: 1,
    name: "Carlos Méndez",
    rating: 5,
    text: "Excelente servicio, muy rápidos y profesionales. Me ayudaron en plena carretera a las 3 AM.",
    date: "2024-03-15"
  },
  {
    id: 2,
    name: "María González",
    rating: 5,
    text: "Super recomendados. Llegaron en menos de 20 minutos cuando tuve una pana en Viña.",
    date: "2024-03-10"
  },
  {
    id: 3,
    name: "Juan Silva",
    rating: 5,
    text: "Precios justos y excelente atención. Los he llamado varias veces y siempre responden.",
    date: "2024-03-05"
  }
];

export default function ReviewsSection() {
  return (
    <section className="py-20 bg-white">
      <div className="container">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Lo que dicen nuestros clientes
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Más de 1,000 clientes satisfechos confían en nuestro servicio
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {REVIEWS.map((review, index) => (
            <motion.div
              key={review.id}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-gray-50 p-6 rounded-lg"
            >
              <div className="flex text-yellow-400 mb-3">
                {[...Array(review.rating)].map((_, i) => (
                  <HiStar key={i} className="h-5 w-5" />
                ))}
              </div>
              <p className="text-gray-700 mb-4">{review.text}</p>
              <div className="flex justify-between items-center text-sm">
                <span className="font-medium">{review.name}</span>
                <span className="text-gray-500">
                  {new Date(review.date).toLocaleDateString('es-CL')}
                </span>
              </div>
            </motion.div>
          ))}
        </div>

        <div className="text-center mt-12">
        <a
            href="https://google.com/maps/place/gruasbechan"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary"
          >
            Ver todas las reseñas en Google
          </a>
        </div>
      </div>
    </section>
  );
}