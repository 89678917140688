import React from 'react';
import { HiPhone, HiChat } from 'react-icons/hi';
import { PHONE_NUMBER, SOCIAL_MEDIA } from '../../utils/constants';

export default function CTAButtons() {
  return (
    <div className="flex flex-col sm:flex-row gap-4">
      <a 
        href={`tel:${PHONE_NUMBER}`}
        className="btn btn-primary"
      >
        <HiPhone className="mr-2 h-5 w-5" />
        Llamar Ahora
      </a>
      <a 
        href={SOCIAL_MEDIA.whatsapp}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-secondary"
      >
        <HiChat className="mr-2 h-5 w-5" />
        WhatsApp
      </a>
    </div>
  );
}