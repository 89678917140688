// src/pages/blog/TowingServicesGuidePost.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { HiTruck, HiShieldCheck, HiClock, HiExclamationCircle } from 'react-icons/hi';
import CTAButtons from '../../components/shared/CTAButtons';

const SERVICES_INFO = [
  {
    title: "Grúa con Plataforma",
    description: "Ideal para vehículos que no pueden rodar o requieren máxima seguridad en el traslado.",
    bestFor: [
      "Vehículos de lujo",
      "Autos con daños en la transmisión",
      "Vehículos accidentados",
      "Traslados largos"
    ],
    icon: HiTruck
  },
  {
    title: "Asistencia en Ruta",
    description: "Servicios rápidos para problemas menores que pueden resolverse en el lugar.",
    bestFor: [
      "Cambio de neumático",
      "Batería descargada",
      "Falta de combustible",
      "Problemas eléctricos simples"
    ],
    icon: HiShieldCheck
  },
  {
    title: "Rescate 24/7",
    description: "Servicio de emergencia disponible a cualquier hora para situaciones críticas.",
    bestFor: [
      "Accidentes",
      "Averías graves",
      "Emergencias nocturnas",
      "Situaciones de riesgo"
    ],
    icon: HiClock
  }
];

export default function TowingServicesGuidePost() {
  return (
    <>
      <Helmet>
        <title>Servicios de Grúa: Todo lo que Debes Saber | Grúas Bechan</title>
        <meta 
          name="description" 
          content="Guía completa sobre servicios de grúa: tipos, cuándo necesitarlos y cómo elegir el servicio adecuado. Información esencial para conductores."
        />
      </Helmet>

      <article className="max-w-4xl mx-auto px-4 py-12">
        <header className="text-center mb-12">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold mb-4"
          >
            Servicios de Grúa: Todo lo que Debes Saber
          </motion.h1>
          <div className="flex items-center justify-center space-x-4 text-gray-600">
            <span>6 minutos de lectura</span>
            <span>•</span>
            <span>Actualizado: {new Date().toLocaleDateString()}</span>
          </div>
        </header>

        <div className="prose prose-lg max-w-none">
          <p className="lead text-xl text-gray-600 mb-8">
            Conocer los diferentes tipos de servicios de grúa puede ayudarte a tomar 
            la mejor decisión en momentos de emergencia. Esta guía te explicará todo 
            lo que necesitas saber.
          </p>

          {SERVICES_INFO.map((service, index) => (
            <motion.section
              key={service.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="mb-12"
            >
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <div className="flex items-center mb-4">
                  <service.icon className="w-8 h-8 text-brand-600 mr-3" />
                  <h2 className="text-2xl font-bold">{service.title}</h2>
                </div>

                <p className="text-gray-700 mb-4">{service.description}</p>

                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-semibold mb-3">Ideal para:</h3>
                  <ul className="space-y-2">
                    {service.bestFor.map((item, idx) => (
                      <li key={idx} className="flex items-center">
                        <span className="text-brand-600 mr-2">•</span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </motion.section>
          ))}

          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-4">¿Cómo Elegir el Servicio Adecuado?</h2>
            <div className="bg-gray-50 p-6 rounded-lg">
              <ol className="space-y-4">
                <li className="flex items-start">
                  <span className="font-bold text-brand-600 mr-2">1.</span>
                  <div>
                    <strong>Evalúa la situación:</strong>
                    <p>Determina si es una emergencia o puede esperar.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <span className="font-bold text-brand-600 mr-2">2.</span>
                  <div>
                    <strong>Considera el tipo de vehículo:</strong>
                    <p>Algunos vehículos requieren equipos especiales.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <span className="font-bold text-brand-600 mr-2">3.</span>
                  <div>
                    <strong>Verifica la cobertura:</strong>
                    <p>Consulta si tu seguro cubre el servicio.</p>
                  </div>
                </li>
              </ol>
            </div>
          </section>

          {/* Tips Section */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-4">Consejos Importantes</h2>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-green-50 p-6 rounded-lg">
                <h3 className="font-semibold mb-3 text-green-800">Qué Hacer</h3>
                <ul className="space-y-2">
                  <li className="flex items-center text-green-700">
                    <span className="mr-2">✓</span>
                    Mantén la calma
                  </li>
                  <li className="flex items-center text-green-700">
                    <span className="mr-2">✓</span>
                    Activa las luces de emergencia
                  </li>
                  <li className="flex items-center text-green-700">
                    <span className="mr-2">✓</span>
                    Ten la documentación lista
                  </li>
                </ul>
              </div>
              <div className="bg-red-50 p-6 rounded-lg">
                <h3 className="font-semibold mb-3 text-red-800">Qué No Hacer</h3>
                <ul className="space-y-2">
                  <li className="flex items-center text-red-700">
                    <span className="mr-2">×</span>
                    Intentar reparaciones peligrosas
                  </li>
                  <li className="flex items-center text-red-700">
                    <span className="mr-2">×</span>
                    Permanecer dentro del vehículo en zonas peligrosas
                  </li>
                  <li className="flex items-center text-red-700">
                    <span className="mr-2">×</span>
                    Aceptar ayuda de servicios no autorizados
                  </li>
                </ul>
              </div>
            </div>
          </section>

          {/* Precios y Consideraciones */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-4">Factores que Afectan el Servicio</h2>
            <div className="bg-blue-50 p-6 rounded-lg">
              <ul className="space-y-4">
                <li className="flex items-start">
                  <HiExclamationCircle className="w-6 h-6 text-blue-600 mr-2 flex-shrink-0 mt-1" />
                  <div>
                    <strong>Distancia del traslado:</strong>
                    <p>El precio puede variar según los kilómetros a recorrer.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <HiExclamationCircle className="w-6 h-6 text-blue-600 mr-2 flex-shrink-0 mt-1" />
                  <div>
                    <strong>Tipo de vehículo:</strong>
                    <p>Diferentes vehículos requieren diferentes tipos de grúa.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <HiExclamationCircle className="w-6 h-6 text-blue-600 mr-2 flex-shrink-0 mt-1" />
                  <div>
                    <strong>Horario del servicio:</strong>
                    <p>Los servicios nocturnos o en días festivos pueden tener tarifas especiales.</p>
                  </div>
                </li>
              </ul>
            </div>
          </section>

          {/* CTA Section */}
          <div className="bg-brand-50 p-8 rounded-lg mt-12">
            <h3 className="text-2xl font-bold mb-4 text-center">
              ¿Necesitas un servicio de grúa?
            </h3>
            <p className="text-center mb-6">
              Estamos disponibles 24/7 para ayudarte. Contáctanos para recibir asistencia inmediata.
            </p>
            <div className="flex justify-center">
              <CTAButtons />
            </div>
          </div>
        </div>
      </article>
    </>
  );
}