// src/components/home/ServiceHighlights.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { SERVICES, SERVICE_CATEGORIES } from '../../utils/constants';
import ServiceCard from '../shared/ServiceCard';
import CTAButtons from '../shared/CTAButtons';
import { HiArrowRight } from 'react-icons/hi';

export default function ServiceHighlights() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Nuestros Servicios
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-lg">
            Ofrecemos una amplia gama de servicios de asistencia en ruta para 
            mantener tu vehículo en movimiento, las 24 horas del día.
          </p>
        </motion.div>

        {/* Categorías de Servicios */}
        {SERVICE_CATEGORIES.map((category, categoryIndex) => (
          <div key={category.id} className="mb-16">
            <motion.h3
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ delay: categoryIndex * 0.1 }}
              className="text-2xl font-bold mb-6 text-gray-800 flex items-center"
            >
              <span className="w-12 h-1 bg-brand-600 mr-4" />
              {category.title}
            </motion.h3>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {category.services.map((serviceId, serviceIndex) => {
                const service = SERVICES.find(s => s.id === serviceId);
                return service ? (
                  <motion.div
                    key={service.id}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: (categoryIndex * 0.1) + (serviceIndex * 0.1) }}
                  >
                    <ServiceCard {...service} />
                  </motion.div>
                ) : null;
              })}
            </div>
          </div>
        ))}

        {/* CTA Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-16"
        >
          <div className="bg-white rounded-2xl shadow-xl p-8 md:p-12">
            <div className="max-w-3xl mx-auto text-center">
              <h3 className="text-2xl md:text-3xl font-bold mb-4">
                ¿Necesitas alguno de nuestros servicios?
              </h3>
              <p className="text-gray-600 mb-8 text-lg">
                Estamos disponibles 24/7 para ayudarte. Contacta con nosotros ahora y 
                recibirás asistencia inmediata.
              </p>
              
              <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
                <CTAButtons />
              </div>

              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: 0.5 }}
                className="mt-8 text-sm text-gray-500"
              >
                <p>
                  También puedes ver todos nuestros servicios detallados
                  <a 
                    href="/servicios" 
                    className="text-brand-600 hover:text-brand-700 inline-flex items-center ml-2"
                  >
                    aquí
                    <HiArrowRight className="ml-1 w-4 h-4" />
                  </a>
                </p>
              </motion.div>
            </div>
          </div>
        </motion.div>

        {/* Información Adicional */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center p-6 bg-white rounded-lg shadow-md"
          >
            <div className="text-4xl font-bold text-brand-600 mb-2">24/7</div>
            <p className="text-gray-600">Disponibilidad garantizada</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-center p-6 bg-white rounded-lg shadow-md"
          >
            <div className="text-4xl font-bold text-brand-600 mb-2">15min</div>
            <p className="text-gray-600">Tiempo promedio de respuesta</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-center p-6 bg-white rounded-lg shadow-md"
          >
            <div className="text-4xl font-bold text-brand-600 mb-2">100%</div>
            <p className="text-gray-600">Satisfacción del cliente</p>
          </motion.div>
        </div>
      </div>
    </section>
  );
}